import { $form, $json,$file } from '@/lib/axios'
let exports = {}

/**
 * 管理员登录
 * @param {Object} params
 *  username 用户名
 *  password 密码
 */
// exports.login = params => {
//   return $json.post('login', params)
// }
// 退出登录
exports.logout = () => $form.post('logout')
// 关于我们
exports.aboutUs = () => $form.get('about-us')
// 公司信息
exports.aboutMe = () => $form.get('me')
// 下载管理 获取列表
exports.exportsDownloads = () => $form.get('export-downloads')
// 常见问题
exports.problemList = params => {
  return $form.get('problems', { params })
}
// 常见问题 详情
exports.problemDetail = id => {
  return $form.get(`problems/${id}`)
}
// 常见问题 分类列表
exports.categoryList = () => {
  return $form.get('problems/categories')
}
// 系统消息 列表
exports.messageList = params => {
  return $form.get('push-messages', { params })
}




//获取登录验证码
exports.register = params => {
return $json.post('register/fch-code', params)
}
//区号
exports.country = params => {
  return $form.get('register/country', { params })
}
//登录
exports.login = params => {
  return $form.post('register/fch',  params )
}
//联系我们

exports.contact = (params) => {
  return $form.post('contact',  params )
}
//修改个人信息
exports.subInformation = (id,params) => {
  return $form.put(`fch-update/${id}`, params)
}
 //获取个人信息
 exports.getInformation = params=> {
  return $form.get('me', params)
}
//提交审核
exports.subAudit = (id,params) => {
  return $form.post(`fch/get-info/${id}`,  params )
}
//获取审核状态
exports.getAudit = (id,params) => {
  return $form.get(`fch/get-info/${id}`,  params )
}
/**
 * 上传图片
 * @param {Array} images 图片对象数组
 *  iamges[*].file 文件
 */
// 上传图片
exports.uploadImg = images => $file.post('upload/images', images)

//获取人脸二维码
exports.faceImg = (id,params) => {
  return $form.get(`fch/faceid/mini/${id}`,  params )
}
//人脸识别状态
exports.faceStatus = (id,params) => {
  return $form.get(`fch/faceid/get-status/${id}`,  params )
}
//开通店铺
exports.fchOpen = (id,params) => {
  return $form.post(`fch/open/${id}`,  params )
}
// 视频课堂
exports.videoClassroom = id => {
  return $form.get(`study/index/${id}`)
}
// 课程详细
exports.videoDetail = (column_id,lessons_id) => {
  return $form.get(`study/info/${column_id}/${lessons_id}`)
}
// 搜索
exports.videoSearch = params => {
  return $form.post('study/search',  params )
}
// 临时访问url
exports.getTemporaryurl = (id,params) => {
  return $form.get(`study/getViewUrl/${id}`,{params})
}
// 提交留资信息
exports.getoutReserved = params => {
  return $form.post('study/saveUserReserveInfo',  params )
}
// 上报用户播放信息
exports.getplayMessage = params => {
  return $form.post('study/savaUserPlayInfo',  params )
}
export default exports