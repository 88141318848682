import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import store from './store'
import request from './lib/request'
import baseUrl from './lib/axios/baseApi'
import vueEsign from 'vue-esign'
import VueCoreVideoPlayer from 'vue-core-video-player'
import VuePreview from 'vue-preview';
Vue.use(VuePreview);
// import VideoPlayer from 'vue-video-player'
// import 'video.js/dist/video-js.css';
// import 'vue-video-player/src/custom-theme.css';
Vue.use(VueCoreVideoPlayer, {
  lang: 'zh-CN'
})
Vue.use(ElementUI);
Vue.use(vueEsign)

store.commit('initToken')
Vue.prototype.$request = request
Vue.prototype.$baseUrl = baseUrl

Vue.config.productionTip = false
Vue.use(Antd);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
