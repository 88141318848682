const hostName = location.hostname
let baseUrl
switch (hostName) {
    case 'smartcatplus.com':
    case 'release.smartcatplus.com':
        baseUrl = {
            BASE_API_URL: 'https://api-jiyun.smartcatplus.com/api/admin',
            IMAGE_URL: 'https://api-jiyun.smartcatplus.com',
            BLOCK_URL: 'https://api-jiyun.smartcatplus.com/block-explain'
        }
        break
    case 'test.smartcatplus.com':
    case 'localhost':
        baseUrl = {
            BASE_API_URL: 'https://jiyun-testapi.smartcatplus.com/api/admin',
            IMAGE_URL: 'https://jiyun-testapi.smartcatplus.com',
             // BLOCK_URL: 'https://jiyun-testapi.beegoplus.com/block-explain'
        }
        break
    default: 
     baseUrl = {
        BASE_API_URL: 'https://jiyun-testapi.smartcatplus.com/api/admin',
        IMAGE_URL: 'https://jiyun-testapi.smartcatplus.com',
     }
     break
}

export default baseUrl
