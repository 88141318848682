import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    scToken: '',
    userName: '', // 用户名
    id:'', //用户id
    uuid:'',
    btnLoading: false,
    languageCode: 'zhCN', // 默认简体中文
  },
  mutations: {
    saveToken(state, data) {
      state.scToken = data
      localStorage.setItem('SCTOKEN', data)
    },
    saveUserId(state, data) {
      state.id = data
      localStorage.setItem('ID', data)
    },
    saveUuid(state, data) {
      state.uuid = data
      localStorage.setItem('uuid', data)
    },
    initToken(state) {
      state.scToken = localStorage.getItem('SCTOKEN') || ''
      state.id = localStorage.getItem('ID') || ''
      state.uuid = localStorage.getItem('uuid') || ''
      state.userName = localStorage.getItem('NAME') || ''
      state.groupMe = localStorage.getItem('me') || ''
    },
    removeToken(state) {
      state.scToken = ''
      localStorage.removeItem('SCTOKEN')
      localStorage.removeItem('language')
    }, 
      switchBtnLoading(state, data) {
      state.btnLoading = data.status
    }, 
    saveName(state, data) {
      state.userName = data
      localStorage.setItem('NAME', data)
    },
    removeName(state) {
      state.userName = ''
      localStorage.removeItem('NAME')
    }, 
  },
  actions: {
  }
})
