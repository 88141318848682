import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { //首页信息
    path: '/home',
    name: 'home',
    component: () => import('../views/homeComponents/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  { //产品与服务
    path: '/productService',
    name: 'productService',
    component: () => import('../views/productService/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  { //关于我们
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutUs/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  { //课堂视频
    path: '/videoClass',
    name: 'videoClass',
    component: () => import('../views/videoClass/index.vue'),
    meta: {
      keepAlive: false
    },
	// beforeEnter: (to, from, next) => {
	//     // 在单个路由的独享守卫逻辑
	// 	console.log(1111)
	// }
  },
  { //课堂详细
    path: '/classDetail',
    name: 'classDetail',
    component: () => import('../views/classDetail/index.vue'),
    meta: {
      keepAlive: false
    }
  },
  { //课堂搜索
    path: '/classroomSearch',
    name: 'classroomSearch',
    component: () => import('../views/classroomSearch/index.vue'),
    meta: {
      keepAlive: false
    }
  },
  { //个人信息
    path: '/personInformation',
    name: 'personInformation',
    component: () => import('../views/personInformation/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  //登录
  { 
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/personInformation/components/agreement.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/personInformation/components/personInfo',
    name: 'personInfo',
    component:  () => import('../views/personInformation/components/personInfo.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/personInformation/components/entryMaterials',
    name: 'entryMaterials',
    component:  () => import('../views/personInformation/components/entryMaterials.vue'),
    meta: {
      keepAlive: true
    }
  },
  { //解决方案
    path: '/solution',
    name: 'solution',
    component: () => import('../views/solution/index.vue'),
    meta: {
      keepAlive: true
    }

  },
  //登录
  { 
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
    meta: {
      keepAlive: false
    }
  },
  { //路由重定向
    path: '/',
    redirect: '/home',
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

// router.beforeEach((to, from, next) => {
//   // 全局前置守卫逻辑
//   // console.log(to)
//   if(to.name == 'videoClass'){
// 	window.open('/videoClass', "_blank");
// 	// next(false)
//   } else {
// 	next()
//   }
  
// });

export default router
