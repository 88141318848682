<template>
  <div id="app">
    <a-layout>
      <!-- 头部信息 -->
      <a-layout-header v-show="$route.meta.keepAlive">
        <Header></Header>
      </a-layout-header>
      <!-- 主要内容展示区 -->
      <a-layout-content>
        <router-view />
      </a-layout-content>
      <!-- 底部信息区域 -->
      <a-layout-footer v-show="$route.meta.keepAlive">
        <Footer></Footer>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      routeKsy: '',
    }
  },
  watch: {
    // '$route': function (to) {
    //   if (to) {
    //     this.routeKsy = to.name
    //     sessionStorage.setItem('routeKsy', to.name)
    //   }
    // }
  },
  created () {
  },
  mounted () {
    localStorage.removeItem('source')
    var url = window.location.href
    let urlStr = url.split('?')[1] || ''
    if (urlStr) {
      let obj = {};
      // 再通过 & 将每一个参数单独分割出来
      let paramsArr = urlStr.split('&')
      for (let i = 0, len = paramsArr.length; i < len; i++) {
        // 再通过 = 将每一个参数分割为 key:value 的形式
        let arr = paramsArr[i].split('=')
        obj[arr[0]] = arr[1];
      }
      if (obj.source) {
        localStorage.setItem('source', obj.source)
      }
    }
  },
  methods: {

  }
} 
</script>

<style lang="scss">
.ant-layout-header,
.ant-layout-content,
.ant-layout-footer {
  margin: 0;
  padding: 0;
  background: #FFF;
}

.ant-layout-header {
  height: 64px;
  box-sizing: border-box;
  background: #FFF !important;

}

.ant-layout-content {
  min-height: calc(100vh - 360px - 64px);
}

.ant-layout-footer {
  height: 300px;
  padding: 0 !important;
  background: #fdd000 !important;
}

.background {
  float: right;
  margin-left: 30px;
}

//规定到屏幕最小宽度为 960px
// 小于960 会出现滚动条
#app {
  width: 100%;
  min-width: 1250px;
  background: #FFFFFF;
}

* {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
}
</style>
