<template>
  <div class="footer">
    <div class="footerWave">
      <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255, 208, 0,0.3" />
          <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255, 208, 0,0.5)" />
          <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255, 208, 0,0.7)" />
          <use xlink:href="#gentle-wave" x="48" y="7" fill="#ffd000" />
        </g>
      </svg>
    </div>
    <div class="footerBox">
      <div class="container">
        <div class="left">
          <div class="logoImg">
            <img class="logo" :src="logoImg" alt="图标加载失败">
          </div>
          <div class="Information">
            <div class="Copywriter">
              <span class="text">帮助全球创业者轻松做集运</span>
            </div>
          </div>
        </div>
        <div class="right" align="right">
          <div class="contactInformation">
            <span> 工作时间： 8:30 - 18:00</span>
          </div>
          <div class="contactInformation">
            <span> 邮箱： yangxinhui@smartcatplus.com</span>
          </div>
          <div class="contactInformation">
            <span> 地址：上海市闵行区黎安路999路大虹桥国际28楼</span>
          </div>
        </div>
        <div class="gzh">
          <img class="gzhImg" src="@/assets/smartcat/gzh.png" alt="">
          <div class="gzhTitle">公众号</div>
        </div>
      </div>
      <div class="contactInformations" v-show="dialogContact">
        <div class="close">
           <img @click="close" class="closeIcon" src="@/assets/smartcat/close.png" alt="">
        </div>
        <div class="contactImg">
          <img class="img" src="@/assets/smartcat/weChat.jpg" alt="">
        </div>
        <div class="contactTitle">
          添加专属集运顾问企微申请免费建站
        </div>
      </div>
      <div class="contactOur" @click="contactOur">
        <img class="img" src="@/assets/smartcat/footerIcon.png" alt="">
      </div>
      <a-row class="lastFooter">
        <a-col :span="24">
          <div>
            Copyright ©{{ new Date().getFullYear() }}&nbsp;&nbsp;|&nbsp;&nbsp;嘿猫嗨购&nbsp;&nbsp;|&nbsp;&nbsp;<a
              href="https://sh.gsxt.gov.cn/{B365663DDB44C7EF2873A13EF99845A3F8B1C6019ED7B6E…A9AF6809D4B664824537B837943600775F661158854230921DAE1DAE1D-1670831147799}"
              target="_blank">营业执照</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/"
              target="_blank">沪ICP备2023009711号-1</a>
          </div>
        </a-col>
      </a-row>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    name
  },
  data () {
    return {
      logoImg: require('@/assets/smartcat/logo.svg'),
      timer:null,
      dialogContact: false
    }
  },
  created(){
    this.Dialog()
  },
  methods: {
    contactOur(){
      this.dialogContact = true
      clearTimeout(this.timer)
    },
    close() {
      this.dialogContact = false
    },
    Dialog(){
       this.timer = setTimeout(() => {
        this.dialogContact = true
        clearTimeout(this.timer)
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  position: relative;

  .footerWave {
    margin-top: -100px;

    .waves {
      position: relative;
      width: 100%;
      height: 15vh;
      margin-bottom: -7px;
      /*Fix for safari gap*/
      min-height: 100px;
      max-height: 150px;
    }

    .content {
      position: relative;
      height: 20vh;
      text-align: center;
      background-color: white;
    }

    /* Animation */

    .parallax>use {
      animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
    }

    .parallax>use:nth-child(1) {
      animation-delay: -2s;
      animation-duration: 7s;
    }

    .parallax>use:nth-child(2) {
      animation-delay: -3s;
      animation-duration: 10s;
    }

    .parallax>use:nth-child(3) {
      animation-delay: -4s;
      animation-duration: 13s;
    }

    .parallax>use:nth-child(4) {
      animation-delay: -5s;
      animation-duration: 20s;
    }

    @keyframes move-forever {
      0% {
        transform: translate3d(-90px, 0, 0);
      }

      100% {
        transform: translate3d(85px, 0, 0);
      }
    }

    /*Shrinking for mobile*/
    @media (max-width: 768px) {
      .waves {
        height: 40px;
        min-height: 40px;
      }

      .content {
        height: 30vh;
      }

      h1 {
        font-size: 24px;
      }
    }
  }


  .footerBox {
    width: 1249px;
    margin: auto;
    background: #fdd000;

    .container {
      display: flex;
      justify-content: space-between;

      .left {
        .logoImg {
          margin-top: 10px;

          .logo {
            width: 364px;
            height: 100px;
          }
        }

        .Information {
          .Copywriter {
            .text {
              display: block;
              margin-top: 20px;
              font-size: 20px;
              font-weight: 700;
              color: rgba(0, 0, 0, 1);
            }
          }
        }

      }

      .right {
        margin-top: 55px;
        margin-left: 440px;

        .contact {
          margin-bottom: 15px;

          .contentOur {

            font-size: 16px;
            font-weight: 700;
            line-height: 22.4px;
            color: rgba(0, 0, 0, 1);

          }
        }

        .contactInformation {
          margin-top: 6px;
          height: 30px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 1);
        }
      }

      .gzh {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 55px;

        .gzhImg {
          width: 100px;
          height: 100px;
        }

        .gzhTitle {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 19.6px;
          color: rgba(0, 0, 0, 1);
        }
      }
    }

    .contactInformations {
      position: fixed;
      bottom: 210px;
      right: 40px;
      width: 240px;
      height: 300px;
      background-color: #ffffff;
      border-radius: 5px;
      box-shadow: 0px 8px 16px  rgba(0, 0, 0, 0.16);
       .close {
        float: right;
        margin-right: 20px;
        margin-top: 16px;
        .closeIcon {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
       }
      .contactImg {
        margin-top: 60px;
        margin-bottom: 25px;
        text-align: center;

        .img {
         width: 136px;
         height: 136px;
        }
      }
      .contactTitle {
        width: 160px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 22.4px;
        color: rgba(51, 51, 51, 1);
        margin: auto;
        text-align: center;
      }
    }

    .contactOur {
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 120px;
      right: 40px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: #ffd000;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.24);
      cursor: pointer;

      .img {
        width: 40px;
        height: 36px;

      }
    }

    .lastFooter {
      margin-top: 40px;
      text-align: center;
      color: rgba(0, 0, 0, 1);

      a {
        color: rgba(0, 0, 0, 1);
      }
    }
  }
}</style>
